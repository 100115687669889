










import { Component, Vue, Watch } from 'vue-property-decorator';
import { COMMODITY_DETAIL, OSS_PREFIX } from '@/lib/constant';
import { Route } from 'vue-router';
import store from '@/store';

@Component({
    name: 'Detail',
})
export default class Detail extends Vue {
    public detail = COMMODITY_DETAIL;

    public get id() {
        return this.$route.params.id;
    }
    public get commodityDetail() {
        return this.detail[this.id] || [];
    }

    @Watch('id')
    public onId() {
        store.commit('util/setQrcode', OSS_PREFIX + `/qrcode/${this.id}.png`);
    }

    public created() {
        this.onId();
    }
}
