










import { Component, Vue } from 'vue-property-decorator';
import { OSS_PREFIX } from '@/lib/constant';

@Component({
    name: 'Explore',
})
export default class Explore extends Vue {
    public images: string[] = [
        OSS_PREFIX + '/explore/explore_1.jpg',
        OSS_PREFIX + '/explore/explore_2.jpg',
        OSS_PREFIX + '/explore/explore_3.jpg',
        OSS_PREFIX + '/explore/explore_4.jpg',
        OSS_PREFIX + '/explore/explore_5.jpg',
        OSS_PREFIX + '/explore/explore_6.jpg',
    ];
}
