

















import { Component, Vue } from 'vue-property-decorator';
import { OSS_PREFIX, ROUTE_NAME } from '@/lib/constant';

@Component({
    name: 'Index',
})
export default class Index extends Vue {
    public slides = [
        {
            id: 'hufu_1',
            left: '33%',
            top: '66%',
            img: OSS_PREFIX + '/slide_1.jpg',
        },
        {
            id: 'asset_slide2',
            left: '30.5%',
            top: '66%',
            img: require('@/assets/images/slide_2.jpg'),
        },
        // {
        //     id: 'hufu_2',
        //     left: '30.5%',
        //     top: '66%',
        //     img: OSS_PREFIX + '/hufu/hufu_2_1.jpg',
        // },
        {
            id: 'hufu_3',
            left: '28%',
            top: '65%',
            img: OSS_PREFIX + '/slide_3_1.jpg',
        },
        {
            id: 'asset_slide4',
            left: '62.2%',
            top: '69.2%',
            img: require('@/assets/images/slide_4.jpg'),
        },
        {
            id: 'asset_slide5',
            left: '62.2%',
            top: '69.2%',
            img: require('@/assets/images/slide_5.jpg'),
        },
        // {
        //     id: 'jiankang_1',
        //     left: '62.2%',
        //     top: '69.2%',
        //     img: OSS_PREFIX + '/slide_5.jpg',
        // },
        {
            id: 'jiankang_1',
            left: '34.2%',
            top: '57%',
            img: OSS_PREFIX + '/slide_6.jpg',
        },
    ];

    public onClickItem(id: string) {
        this.$router.push({
            name: ROUTE_NAME.DETAIL,
            params: {
                id,
            },
        });
    }
}
